// Other Imports
import {useGetRandomSeededArray} from "./useGetRandomSeededArray";
import {useEffect, useState} from "react";
import characterDataJson from "../CartoonDataSets/cartoonCharacters.json"
import {CharacterData, defaultDetailedCharacterList, DetailedCharacter} from "../DataTypes/CharacterDataTypes";
import {useGetDailyDataOffset} from "./useGetDailyDataOffset";

export const useGenerateTodaysToons = (numToons : number) => {

    // Custom Hooks
    const {generateRandomSeededArray} = useGetRandomSeededArray();
    const {getDailyDataOffset} = useGetDailyDataOffset();

    // Local Data
    const [characterDataSet] = useState<CharacterData[]>(characterDataJson.CharacterData);

    // Exported Data
    const [titleList, setTitleList] = useState<string[]>([]);
    const [characterList, setCharacterList] = useState<string[]>([]);
    const [todaysToons, setTodaysToons] = useState<DetailedCharacter[]>(defaultDetailedCharacterList(numToons));

    useEffect(() => {
        let dailyOffset : number = getDailyDataOffset() * numToons;
        let detailedCharacterList : DetailedCharacter[] = [];
        let titles : string[] = [];
        let toons : string[] = [];
        let randomSeededArray : number[];
        characterDataSet.forEach((characterData) => {
            const title = characterData.title;
            const type = characterData.type;
            characterData.characters.forEach((character) => {
                const detailedCharacter : DetailedCharacter = {
                    name: character.name,
                    resource: character.resource,
                    title: title,
                    type: type
                }
                toons.push(character.name);
                detailedCharacterList.push(detailedCharacter);
            })
            titles.push(title);
        });

        randomSeededArray = generateRandomSeededArray(detailedCharacterList.length);
        while (dailyOffset > detailedCharacterList.length) {dailyOffset -= detailedCharacterList.length;}
        let todaysDetailedCharacterList : DetailedCharacter[] = [];
        for (let i = 0; i < numToons; i++) {
            if (dailyOffset + i >= randomSeededArray.length) {
                todaysDetailedCharacterList.push(detailedCharacterList[randomSeededArray[dailyOffset + i - randomSeededArray.length]]);
            } else {
                todaysDetailedCharacterList.push(detailedCharacterList[randomSeededArray[dailyOffset + i]]);
            }
        }
        setTodaysToons(todaysDetailedCharacterList);
        setTitleList(titles);
        setCharacterList(toons);
    },[]);

    return {todaysToons, characterList, titleList};
};