// React Imports
import React from 'react';

// MUI Imports
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";

// Custom Imports

interface Props {
    drawerWidth : number,
    headerActive : boolean
}

function MyHeader(drawerWidth : number) {
    return (
        <AppBar position="fixed" sx={{width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`}}>
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    Permanent drawer
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default function Header(props : Props) {
    const headerActive = props.headerActive;
    const drawerWidth = headerActive ? props.drawerWidth : 0;

    return (headerActive ? MyHeader(drawerWidth) : <></>)
}

