export const useGetDailyDataOffset = () => {

    const getDailyDataOffset = () => {
        // Amount of milliseconds in a day
        const millsInDay = 86400000;
        // Generates the milliseconds since January 1970 until June 1st, 2024
        const startingDate = Date.UTC(2024, 5, 1);
        // Generates the current date
        const today = Date.now()
        // Calculates how many days have past since the starting day
        return Math.floor((today - startingDate) / millsInDay);
    }

    return {getDailyDataOffset};
};