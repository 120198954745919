// React Imports
import * as React from "react";
import { Routes, Route } from "react-router-dom";

// MUI Imports
import {ThemeProvider} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';

// Custom Imports
import Sidebar from "./AppComponents/Sidebar";
import Header from "./AppComponents/Header";
import {darkTheme} from "./Themes/darkTheme";
import {lightTheme} from "./Themes/lightTheme";
import BodyContainer from "./AppComponents/BodyContainer";
import Cartoodle from "./AppPages/Cartoodle/Cartoodle";
import GuessTheCartoon from "./AppPages/GuessTheCartoon/GuessTheCartoon";
import GuessTheOpening from "./AppPages/GuessTheOpening/GuessTheOpening";
import GuessTheToon from "./AppPages/GuessTheToon/GuessTheToon";
import Box from "@mui/material/Box";


function App() {

    const darkMode: boolean = true;
    const headerActive: boolean = false;
    const drawerWidth: number = 240;

    return (
        <Box className="App" sx={{display: "flex", height: "100%", width: "100%"}}>
            <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
                <CssBaseline/>
                <Header headerActive={headerActive} drawerWidth={drawerWidth}/>
                <Sidebar headerActive={headerActive} drawerWidth={drawerWidth}/>
                <BodyContainer>
                    <Routes>
                        <Route path={"/Cartoodle"} element={<Cartoodle/>}/>
                        <Route path={"/GuessTheCartoon"} element={<GuessTheCartoon/>}/>
                        <Route path={"/GuessTheOpening"} element={<GuessTheOpening/>}/>
                        <Route path={"/GuessTheToon"} element={<GuessTheToon/>}/>
                    </Routes>
                </BodyContainer>
            </ThemeProvider>
        </Box>
    );
}

export default App;
