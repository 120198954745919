// Custom Imports
import {CartoonDataTypes} from "../DataTypes/CartoonDataTypes";

export const useFilterCartoonData = () => {

    const filterDuplicateTitles = (cartoonListList : CartoonDataTypes[][]) => {
        let filteredListList : CartoonDataTypes[][] = [];

        cartoonListList.forEach(() => {
            filteredListList.push([]);
        });

        cartoonListList.forEach((cartoonList, i) => {
            cartoonList.forEach((cartoon, j) => {
                let isDuplicate = false;
                if (i !== 0) {
                    let currentIndex = 0;
                    while(currentIndex <= i) {
                        filteredListList[currentIndex].forEach((alreadyFilteredCartoon) => {
                            if (alreadyFilteredCartoon.Title === cartoon.Title) {
                                //console.log("Found a duplicate");
                                isDuplicate = true;
                            }
                        });
                        currentIndex++;
                    }
                }
                if (!isDuplicate) {
                    filteredListList[i].push(cartoon);
                }
            });
        });

        return filteredListList;
    }

    const filterByCountry = (unfilteredList : CartoonDataTypes[]) => {
        return unfilteredList.filter((item) => {
            return item.Country.includes("United States")
                || item.Country.includes("Canada")
                || item.Country.includes("US");
        });
    }

    const filterByOrigin = (unfilteredList : CartoonDataTypes[]) => {
        return unfilteredList.filter((item, index) => {
            if (item["Original Channel"] === "") {
                return false;
            }
            return item["Original Channel"].includes("Disney")
                // || item["Original Channel"].includes("Fox")
                || item["Original Channel"].includes("Nickelodeon")
                || item["Original Channel"].includes("Nick Jr.")
                || item["Original Channel"].includes("Cartoon Network")
                // || item["Original Channel"].includes("PBS Kids")
                // || item["Original Channel"].includes("Adult Swim");
        });
    }

    const filterMovieData = (cartoonLists : CartoonDataTypes[][]) => {
        const duplicatesFilteredData : CartoonDataTypes[][] = filterDuplicateTitles(cartoonLists);
        const duplicatesFilteredDataLength : number = duplicatesFilteredData.length;

        let preCountryFilterData : CartoonDataTypes[] = [];
        duplicatesFilteredData.forEach((cartoon, index) => {
            preCountryFilterData.push(...duplicatesFilteredData[duplicatesFilteredDataLength - index - 1])
        });

        const countryFilteredData : CartoonDataTypes[] = filterByCountry(preCountryFilterData);
        return filterByOrigin(countryFilteredData);
    }

    return {filterMovieData};
};