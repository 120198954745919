import Box from "@mui/material/Box";
import * as React from "react";
import {styled} from "@mui/material/styles";
import {Autocomplete, TextField, useTheme} from "@mui/material";
import {DetailedCharacter} from "../../../DataTypes/CharacterDataTypes";
import {useState} from "react";
import Typography from "@mui/material/Typography";

export const CharacterGuessBoxAutocompleteBox = styled(Box)(({theme}) => ({
    width: "100%",
    margin: "5% 0 5% 0",
}));

export const CharacterGuessBoxContainer = styled(Box)(({theme}) => ({
    flexGrow: "1",
    height: "100%",
    width: "100%",
}));

export const CharacterGuessBoxImageBox = styled(Box)(({theme}) => ({
    flexGrow: "1",
    borderRadius: "4%",
    height: "40vh",
    maxWidth: "100%",
    backgroundColor: "white",
    overflow: "hidden",
    display: "flex",
    // alignContent: "center",
    // justifyContent: "center",
}));

interface Props {
    detailedCharacter : DetailedCharacter,
    titleList : string[],
    characterList : string[],
    finalGuessMode: boolean
}

export default function CharacterGuessBox(props : Props) {

    const theme = useTheme();
    const source : string = props.detailedCharacter.resource;
    const name : string = props.detailedCharacter.name;
    const title : string = props.detailedCharacter.title;
    const finalGuessMode : boolean = props.finalGuessMode;
    const characterList : string[] = props.characterList;
    const titleList : string[] = props.titleList;

    const [guessedCharacter, setGuessedCharacter] = useState<string>("");
    const [guessedTitle, setGuessedTitle] = useState<string>("");

    const determineGuessColor = (answer : string, guess : string) => {
        if (!finalGuessMode) {
            return "primary";
        } else if (answer === guess) {
            return "green"
        }
        return "red";
    }


    return (
        <CharacterGuessBoxContainer>
            <CharacterGuessBoxImageBox>
                <img alt={name} style={{objectFit: "cover", minHeight: "100%", minWidth: "100%"}} src={source}/>
            </CharacterGuessBoxImageBox>
            {
                finalGuessMode ?
                    <Typography borderRadius={"100%"} bgcolor={determineGuessColor(name, guessedCharacter)}>Character Name: {name}</Typography>
                    :
                    <CharacterGuessBoxAutocompleteBox>
                        <Autocomplete
                            freeSolo
                            value={guessedCharacter}
                            disableClearable
                            options={characterList}
                            onInputChange={(event, value) => {
                                setGuessedCharacter(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Name"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </CharacterGuessBoxAutocompleteBox>
            }
            {
                finalGuessMode ?
                    <Typography borderRadius={"100%"} bgcolor={determineGuessColor(title, guessedTitle)}>Title: {title}</Typography>
                    :
                    <CharacterGuessBoxAutocompleteBox>
                        <Autocomplete
                            freeSolo
                            value={guessedTitle}
                            disableClearable
                            options={titleList}
                            onInputChange={(event, value) => {
                                setGuessedTitle(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Cartoon"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </CharacterGuessBoxAutocompleteBox>
            }
        </CharacterGuessBoxContainer>
    )
}