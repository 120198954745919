// React Imports
import * as React from "react";
import {useEffect, useState} from "react";

// MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Autocomplete, Grid, IconButton, Stack, TextField} from "@mui/material";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Divider from "@mui/material/Divider";

// Custom Imports
// @ts-ignore
import cartoonOld from "../../CartoonDataSets/1987 - 2022.csv";
// @ts-ignore
import cartoonNew from "../../CartoonDataSets/2023.csv";
import {useImportCartoonCSV} from "../../Hooks/useImportCartoonCSV";
import {useGetRandomSeededArray} from "../../Hooks/useGetRandomSeededArray";
import {CartoonDataTypes, defaultCartoonData} from "../../DataTypes/CartoonDataTypes";
import {useFilterCartoonData} from "../../Hooks/useFilterCartoonData";
import {TitleBox} from "../Shared/TitleBox";
import {ALREADY_GUESSED, NO_MATCH} from "../../DataTypes/GuessType";
import {useLevenShtein} from "../../Hooks/useLevenShtein";
import {
    DeltaIcon,
    FormattedNumberGuessInfo,
    GuessInfoBox,
    GuessInfoBoxContainer,
    GuessInfoGridDivider,
    GuessInfoGridGuessSection,
} from "./StyledComponents/GuessInfoBox";
import {
    GuessEntryBox,
    GuessEntryBoxButton,
    GuessEntryBoxSelection,
    GuessEntryNumGuessesBox
} from "./StyledComponents/GuessEntryBox";
import {useGetDailyDataOffset} from "../../Hooks/useGetDailyDataOffset";

export default function Cartoodle() {

    // Special Hooks
    const {getStringSimilarity} = useLevenShtein();

    // Grid Constants
    const TITLE_COLUMNS : number = 3;
    const ORIGIN_COLUMNS : number = 3;
    const COUNTRY_COLUMNS : number = 3;
    const PREMIER_YEAR_COLUMNS : number = 3.5;
    const FINAL_YEAR_COLUMNS : number = 3;
    const SEASONS_COLUMNS : number = 2.5;
    const EPISODES_COLUMNS : number = 2.5;
    const TECHNIQUE_COLUMNS : number = 2.5;
    const SPACING_COLUMNS : number = 1;
    const TOTAL_COLUMNS : number = TITLE_COLUMNS
        + ORIGIN_COLUMNS
        + COUNTRY_COLUMNS
        + PREMIER_YEAR_COLUMNS
        + FINAL_YEAR_COLUMNS
        + SEASONS_COLUMNS
        + EPISODES_COLUMNS
        + TECHNIQUE_COLUMNS;

    // Guessing Constants
    const MAX_GUESSES : number = 21;

    // Data Manipulation State
    const {state : cartoonData_1987} = useImportCartoonCSV(cartoonOld);
    const {state : cartoonData_2023} = useImportCartoonCSV(cartoonNew);
    const [combinedCartoonData, setCombinedCartoonData] = useState<CartoonDataTypes[]>([]);
    const {generateRandomSeededArray} = useGetRandomSeededArray();
    const {filterMovieData} = useFilterCartoonData();
    const [randomArray, setRandomArray] = useState<number[]>([]);
    const [todayAnswer, setTodayAnswer] = useState<CartoonDataTypes>(defaultCartoonData);
    const {getDailyDataOffset} = useGetDailyDataOffset()

    // Input State
    const [cartoonSelection, setCartoonSelection] = useState<string>("");
    const [guessedCartoons, setGuessedCartoons] = useState<CartoonDataTypes[]>([])
    const [numGuesses, setNumGuesses] = useState<number>(0);
    const [invalidReason, setInvalidReason] = useState<string>("");
    const [correctlyAnswered, setCorrectlyAnswered] = useState<boolean>(false);


    useEffect(() => {
        if (cartoonData_1987.length > 0 && cartoonData_2023.length > 0) {
            const allDataReverseChronologicalOrder : CartoonDataTypes[][] = [cartoonData_2023, cartoonData_1987];
            const filteredData = filterMovieData(allDataReverseChronologicalOrder);
            const tempFilteredLength : number = filteredData.length;
            setCombinedCartoonData(filteredData);
            console.log("Combined Data set:");
            console.log(filteredData);
            // @ts-ignore
            setRandomArray(generateRandomSeededArray(tempFilteredLength));
        }
    }, [cartoonData_1987, cartoonData_2023])

    useEffect(() => {
        if (randomArray.length > 0) {
            const answer : CartoonDataTypes = combinedCartoonData[randomArray[getDailyDataOffset()]];
            setTodayAnswer(answer);
            console.log("Today's Answer: ");
            console.log(answer);
        }
    },[randomArray]);

    const handleGuess = () => {
        let validGuess : boolean = false;
        let guessInfo : CartoonDataTypes;
        combinedCartoonData.forEach((cartoon) => {
            if (cartoon.Title === cartoonSelection) {
                validGuess = true;
                guessInfo = cartoon;
            }
            setInvalidReason(NO_MATCH);
        });

        guessedCartoons.forEach((cartoon) => {
            if (cartoon.Title === cartoonSelection) {
                validGuess = false;
                guessInfo = cartoon;
                setInvalidReason(ALREADY_GUESSED);
            }
        })

        // Returns if the guess is not in the list of cartoons
        // TODO: Add text that indicates choice was not valid
        if (!validGuess) {
            return;
        } else if (cartoonSelection !== todayAnswer.Title) {
            setGuessedCartoons([guessInfo!, ...guessedCartoons]);
        } else {
            setCorrectlyAnswered(true);
            setGuessedCartoons([guessInfo!, ...guessedCartoons]);
        }

        setCartoonSelection("");
        setNumGuesses(numGuesses + 1);
        setInvalidReason("");
    }

    const checkIsSameOrigin = (c1 : CartoonDataTypes, c2 : CartoonDataTypes) => {
        return c1["Original Channel"] === c2["Original Channel"];
    }

    const checkIsSameCountry = (c1 : CartoonDataTypes, c2 : CartoonDataTypes) => {
        return c1.Country === c2.Country;
    }

    const checkIsSameTechnique = (c1 : CartoonDataTypes, c2 : CartoonDataTypes) => {
        return c1.Technique === c2.Technique;
    }

    const getPremiereYearDelta = (c1 : CartoonDataTypes, c2 : CartoonDataTypes) => {
        if (c1["Premiere Year"] === "" || c2["Premiere Year"] === "") {
            return NaN;
        }
        const c1Year = parseInt(c1["Premiere Year"]);
        const c2Year = parseInt(c2["Premiere Year"]);

        return c1Year - c2Year;
    }

    const getFinalYearDelta = (c1 : CartoonDataTypes, c2 : CartoonDataTypes) => {
        if (c1["Final Year"] === "" || c2["Final Year"] === "") {
            return NaN;
        }
        const c1Year = parseInt(c1["Final Year"]);
        const c2Year = parseInt(c2["Final Year"]);

        return c1Year - c2Year;
    }

    const getSeasonDelta = (c1 : CartoonDataTypes, c2 : CartoonDataTypes) => {
        if (c1.Seasons === "" || c2.Seasons === "") {
            return NaN;
        }
        const c1Year = parseInt(c1.Seasons);
        const c2Year = parseInt(c2.Seasons);

        return c1Year - c2Year;
    }

    const getEpisodeDelta = (c1 : CartoonDataTypes, c2 : CartoonDataTypes) => {
        if (c1.Episodes === "" || c2.Episodes === "") {
            return NaN;
        }
        const c1Year = parseInt(c1.Episodes);
        const c2Year = parseInt(c2.Episodes);

        return c1Year - c2Year;
    }

    const getSimilarityColor = (percentSimilar : number) => {
        return percentSimilar === 100 ? "green" : percentSimilar > 50 ? "yellow" : "red";
    }


    return (
        <>
            <Stack textAlign={"center"}>
                <TitleBox>
                    <Typography variant={"h2"} display={"inline"}>
                        <strong>Cartoodle</strong>
                    </Typography>
                    <Box display={"inline"} justifyContent={"center"} flexGrow={"2"}>
                        <IconButton>
                            <QuestionMarkIcon fontSize={"small"}/>
                        </IconButton>
                    </Box>
                </TitleBox>
                {
                    !correctlyAnswered && numGuesses >= MAX_GUESSES ?
                        <Box>
                            <Typography variant={"h4"}>Oh No! You've Ran Out of Guesses!</Typography>
                            <Typography>The Correct Answer was: {todayAnswer.Title}</Typography>
                        </Box>
                        : <></>
                }
                {
                    correctlyAnswered ? <Typography color={"green"} variant={"h2"}>Congrats! You've Guessed Correctly!</Typography> : <></>
                }
                <GuessEntryBox>
                    <GuessEntryNumGuessesBox>
                        <Typography variant={"h6"}>
                            <strong>Guess {numGuesses} / {MAX_GUESSES}</strong>
                        </Typography>
                    </GuessEntryNumGuessesBox>
                    <GuessEntryBoxSelection>
                        <Autocomplete
                            freeSolo
                            value={cartoonSelection}
                            disableClearable
                            options={combinedCartoonData.map((option) => option.Title)}
                            onInputChange={(event, value) => {setCartoonSelection(value)}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Cartoons"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                    </GuessEntryBoxSelection>
                    <GuessEntryBoxButton disabled={correctlyAnswered || numGuesses >= MAX_GUESSES} onClick={()=>{handleGuess()}}>
                        <strong>Guess</strong>
                    </GuessEntryBoxButton>
                </GuessEntryBox>
                {
                    invalidReason !== "" ? <Typography>{invalidReason}</Typography> : <></>
                }
                <GuessInfoBoxContainer>
                    <GuessInfoBox>
                        <Grid container spacing={SPACING_COLUMNS} columns={TOTAL_COLUMNS} textAlign={"center"}>
                            <Grid item xs={TITLE_COLUMNS}>
                                <Typography>
                                    Title
                                </Typography>
                            </Grid>
                            <Grid item xs={ORIGIN_COLUMNS}>
                                <Typography>
                                    Origin
                                </Typography>
                            </Grid>
                            <Grid item xs={COUNTRY_COLUMNS}>
                                <Typography>
                                    Country
                                </Typography>
                            </Grid>
                            <Grid item xs={PREMIER_YEAR_COLUMNS}>
                                <Typography>
                                    Premier Year
                                </Typography>
                            </Grid>
                            <Grid item xs={FINAL_YEAR_COLUMNS}>
                                Final Year
                            </Grid>
                            <Grid item xs={SEASONS_COLUMNS}>
                                <Typography>
                                    Seasons
                                </Typography>
                            </Grid>
                            <Grid item xs={EPISODES_COLUMNS}>
                                <Typography>
                                    Episodes
                                </Typography>
                            </Grid>
                            <Grid item xs={TECHNIQUE_COLUMNS}>
                                <Typography>
                                    Technique
                                </Typography>
                            </Grid>
                        </Grid>
                            {
                                guessedCartoons.map((cartoon) => {
                                    const titleSimilar : number = getStringSimilarity(cartoon.Title, todayAnswer.Title)
                                    const titleSimilarColor : string = getSimilarityColor(titleSimilar);
                                    const sameOrigin : boolean = checkIsSameOrigin(todayAnswer, cartoon);
                                    const sameCountry : boolean = checkIsSameCountry(todayAnswer, cartoon);
                                    const sameTechnique : boolean = checkIsSameTechnique(todayAnswer, cartoon)
                                    const premierDelta : number = getPremiereYearDelta(todayAnswer, cartoon);
                                    const finalDelta : number = getFinalYearDelta(todayAnswer, cartoon);
                                    const seasonDelta : number = getSeasonDelta(todayAnswer, cartoon);
                                    const episodeDelta : number = getEpisodeDelta(todayAnswer, cartoon);

                                    return (
                                        <Box key={cartoon.Title}>
                                        <GuessInfoGridDivider/>
                                        <GuessInfoGridGuessSection
                                            container
                                            spacing={SPACING_COLUMNS}
                                            columns={TOTAL_COLUMNS}
                                            textAlign={"center"}
                                        >
                                            <Grid item xs={TITLE_COLUMNS}>
                                                <Typography display={"relative"}>
                                                    {cartoon.Title}
                                                </Typography>
                                                <Divider/>
                                                <Typography color={titleSimilarColor} fontFamily={"Ultra"}>
                                                    <strong>{titleSimilar}% Similar</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={ORIGIN_COLUMNS} alignSelf={"center"}>
                                                <Typography color={sameOrigin ? "green" : "red"}>
                                                    {cartoon["Original Channel"]}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={COUNTRY_COLUMNS} alignSelf={"center"}>
                                                <Typography color={sameCountry ? "green" : "red"}>
                                                    {cartoon.Country}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={PREMIER_YEAR_COLUMNS} alignSelf={"center"}>
                                                <FormattedNumberGuessInfo delta={premierDelta} text={cartoon["Premiere Year"]}/>
                                                <DeltaIcon delta={premierDelta}/>
                                            </Grid>
                                            <Grid item xs={FINAL_YEAR_COLUMNS} alignSelf={"center"}>
                                                <FormattedNumberGuessInfo delta={finalDelta} text={cartoon["Final Year"]}/>
                                                <DeltaIcon delta={finalDelta}/>
                                            </Grid>
                                            <Grid item xs={SEASONS_COLUMNS} alignSelf={"center"}>
                                                <FormattedNumberGuessInfo delta={seasonDelta} text={cartoon.Seasons}/>
                                                <DeltaIcon delta={seasonDelta}/>
                                            </Grid>
                                            <Grid item xs={EPISODES_COLUMNS} alignSelf={"center"}>
                                                <FormattedNumberGuessInfo delta={episodeDelta} text={cartoon.Episodes}/>
                                                <DeltaIcon delta={episodeDelta}/>
                                            </Grid>
                                            <Grid item xs={TECHNIQUE_COLUMNS} alignSelf={"center"}>
                                                <Typography color={sameTechnique ? "green" : "red"}>
                                                    {cartoon.Technique}
                                                </Typography>
                                            </Grid>
                                        </GuessInfoGridGuessSection>
                                        </Box>
                                    )
                                })
                            }
                    </GuessInfoBox>
                </GuessInfoBoxContainer>
            </Stack>
        </>
    )
}