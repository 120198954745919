// React Imports
import React from 'react';
import {useNavigate} from "react-router-dom";

// MUI Imports
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QuizIcon from '@mui/icons-material/Quiz';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

// Custom Imports
import Box from "@mui/material/Box";
// Source for text images: https://www.fontspace.com/super-bubble-font-f100606
import LogoTextTop from "../Media/LogoText/GuessThe.png";
import LogoTextBottom from "../Media/LogoText/Cartoon.png";


interface Props {
    drawerWidth : number,
    headerActive : boolean
}

export default function Sidebar(props : Props) {

    const drawerWidth = props.drawerWidth;
    const navigate = useNavigate();

    const itemData = [
        {
            img: LogoTextTop,
            title: 'Guess the',
        },
        {
            img: LogoTextBottom,
            title: 'Cartoon',
        }
    ];

    const sidebarRoutes: { name: string, icon: any, route: () => void }[] = [
        {
            name: "Cartoodle", icon: <QuizIcon/>, route: () => {
                navigate("/Cartoodle");
            }
        },
        // {
        //     name: "Guess the Cartoon", icon: <LiveTvIcon/>, route: () => {
        //         navigate("/GuessTheCartoon");
        //     }
        // },
        {
            name: "Guess The Toon", icon: <FaceRetouchingNaturalIcon/>, route: () => {
                navigate("/GuessTheToon");
            }
        },
        // {
        //     name: "Guess the Opening", icon: <MusicNoteIcon/> , route: () => {
        //         navigate("/GuessTheOpening");
        //     }
        // }
        // ,
        // {
        //     name: "Hangmouse", icon: <MusicNoteIcon/> , route: () => {
        //         navigate("/Hangmouse");
        //     }
        // }
    ];

    return (
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Box
                    sx={{
                        '&:hover': {cursor: "pointer"},
                    }}
                    m={"15px"}
                    flexDirection={"column"}
                    onClick={() => {navigate("/")}}

                >
                    {itemData.map((item) => (
                        <img
                            style={{maxWidth: "100%"}}
                            srcSet={`${item.img}`}
                            src={`${item.img}`}
                            alt={item.title}
                            loading="lazy"
                            key={item.title}
                        />
                    ))}
                </Box>
                <Divider />
                <List>
                    {sidebarRoutes.map((item) => (
                        <ListItem key={item.name} disablePadding sx={{display: 'block'}} onClick={item.route}>
                            <ListItemButton
                                sx={{px: 2.5,}}
                            >
                                <ListItemIcon sx={{ minWidth: 0, mr: 3, justifyContent: 'center'}}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
    );
}