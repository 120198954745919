export interface CartoonDataTypes {
    Country: string,
    Episodes: string,
    "Final Year": string,
    "Original Channel": string,
    "Premiere Year": string,
    Seasons: string,
    Technique: string
    Title: string,
}

export const defaultCartoonData = {
    "Final Year": "",
    "Original Channel": "",
    "Premiere Year": "",
    Country: "",
    Episodes: "",
    Seasons: "",
    Technique: "",
    Title: ""
}