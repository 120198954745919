// MUI Imports
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";

export const GuessEntryBox = styled(Box)(({theme}) => ({
    display:"flex",
    margin: "0 15% 0 15%",
}));

export const GuessEntryNumGuessesBox = styled(Box)(({theme}) => ({
    textAlign: "center",
    alignSelf: "center",
    padding: "0 2% 0 0",
    width: "80px",
}));

export const GuessEntryBoxSelection = styled(Box)(({theme}) => ({
    width: "65%",
    alignSelf: "center",
}));

export const GuessEntryBoxButton = styled(Button)(({theme}) => ({
    width: "85px",
    height: "58px",
    margin: "0 10px 0 10px",
    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.common.black}`,
}));