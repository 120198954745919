export interface CharacterDataSet {
    characterSets: CharacterData[]
}

export interface CharacterData {
    title: string,
    type: string,
    characters: Character[]
}

export interface Character {
    name: string,
    resource: string
}

export interface DetailedCharacter {
    title: string,
    type: string,
    name: string,
    resource: string
}

export const defaultDetailedCharacter : DetailedCharacter = {
    title: "",
    type: "",
    name: "",
    resource: ""
}

export const defaultDetailedCharacterList = (numCharacters : number) => {
    const defaultList : DetailedCharacter[]  = [];
    for (let i : number = 0; i < numCharacters; i++) {
        defaultList.push(
            {
                title: "",
                type: "",
                name: "",
                resource: ""
            }
        )
    }
    return defaultList;
}