// React Imports
import {useEffect, useState} from "react";

// Custom Imports
import {CartoonDataTypes} from "../DataTypes/CartoonDataTypes";

// Other Imports
import {readString} from "react-papaparse";

export const useImportCartoonCSV = (csvLink : string) => {

    const [state, setState] = useState<CartoonDataTypes[]>([]);

    const papaConfig : any = {
        complete: (results : any, file : any) => {
            console.log('Parsing complete:', results, file);
            setState(results.data);
        },
        download: true,
        error: (error : any, file : any) => {
            console.log('Error while parsing:', error, file);
        },
        header: true
    };

    useEffect(() => {
        readString(csvLink, papaConfig);
    },[])

    return {state};
};