// React Imports
import * as React from "react";

// MUI Imports
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export const GuessInfoBoxContainer = styled(Box)(({theme}) => ({
    padding: "3%",
    textAlign: "left",
}));

export const GuessInfoBox = styled(Box)(({theme}) => ({
    borderRadius: "10px",
    minWidth:"700px",
    padding:"20px",
    backgroundColor: `${theme.palette.grey["900"]}`,
    flexGrow: "1",
}));

export const GuessInfoGridTitleSection = styled(Grid)(({theme}) => ({
}));

export const GuessInfoGridGuessSection = styled(Grid)(({theme}) => ({
}));

export const GuessInfoGridDivider = styled(Divider)(({theme}) => ({
    padding:"10px 0 0 0",
    margin:"0 0 10px -20px",
    width: "calc(100% + 40px)",
    minWidth:"700px",
    borderColor: `${theme.palette.common.black}`
}));

interface DeltaIconProps {
    delta : number,
}

interface FormattedGuessInfo {
    delta : number,
    text : string,
}

export const FormattedNumberGuessInfo = (props : FormattedGuessInfo) => {
    const delta : number = props.delta;
    const text : string = props.text;
    return (
        <Typography color={delta === 0 ? "green" : "red"}>
            {text}
        </Typography>
    )
}

export const DeltaIcon = (props : DeltaIconProps) => {
    const delta : number = props.delta
    if (isNaN(delta)) {
        return (
            <a style={{position: "relative", alignSelf: "center", color: "yellow"}}>
                <QuestionMarkIcon fontSize={"large"}/>
            </a>
        )
    } else if (delta === 0) {
        return (
            <a style={{position: "relative", alignSelf: "center", color: "green"}}>
                <CheckIcon fontSize={"large"}/>
            </a>
        )
    } else if (delta < 0) {
        return (
            <a style={{position: "relative", alignSelf: "center", color: "red"}}>
                <ArrowDownwardIcon fontSize={"large"}/>
            </a>
        )
    } else {
        return (
            <a style={{position: "relative", alignSelf: "center", color: "red"}}>
                <ArrowUpwardIcon fontSize={"large"}/>
            </a>
        )
    }
}