// React Imports
import * as React from "react";

// MUI Imports
import Box from "@mui/material/Box";

// Custom Imports


export default function GuessTheCartoon() {

    return (
        <Box>

        </Box>
    )
}