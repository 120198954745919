// Other Imports
import Rand from 'rand-seed';

export const useGetRandomSeededArray = () => {

    const generateRandomSeededArray = (size : number) => {
        if (size === 0) {
            return [];
        }

        const tempArray : number[] = [];

        const rng = new Rand("cartoon");
        while (tempArray.length !== size) {
            let randomNum = Math.floor(rng.next() * 10000000000000000) % size;
            while(tempArray.includes(randomNum)) {
                randomNum = randomNum + 1;
                if (randomNum > tempArray.length) {
                    randomNum = 0;
                }
            }
            tempArray.push(randomNum);
        }
        return tempArray;
    }

    return {generateRandomSeededArray};
};