// MUI Imports
import {createTheme} from "@mui/material";

export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#e88484"
        }
    },
    typography: {
        fontFamily: ["Lato", "YuGothic"].join(","),
    },
});
