// MUI Imports
import {createTheme} from "@mui/material";

export const lightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#e88484"
        }
    },
    typography: {
        fontFamily: ["Lato", "YuGothic"].join(","),
    },
});
