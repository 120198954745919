// React Imports
import * as React from "react";

// MUI Imports
import Box from "@mui/material/Box";
import {TitleBox} from "../Shared/TitleBox";
import Typography from "@mui/material/Typography";
import {Button, Grid, IconButton, Stack, useTheme} from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CharacterGuessBox from "./StyledComponents/CharacterGuessBox";
import {styled} from "@mui/material/styles";
import {useGenerateTodaysToons} from "../../Hooks/useGenerateTodaysToons";
import {useState} from "react";

// Custom Imports

export const GridContainer = styled(Box)(({theme}) => ({
    flexGrow: 2,
}));

interface Props {
    answerMode : boolean
}

export default function GuessTheToon() {

    const NUM_TOONS : number = 4;
    const theme = useTheme();
    const {todaysToons, titleList, characterList} = useGenerateTodaysToons(NUM_TOONS);
    const [finalGuessMode, setFinalGuessMode] = useState<boolean>(false);

    return (
        <Stack justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
            <TitleBox>
                <Typography variant={"h2"} display={"inline"}>
                    <strong>Guess the Toon</strong>
                </Typography>
                <Box display={"inline"} justifyContent={"center"} flexGrow={"2"}>
                    <IconButton>
                        <QuestionMarkIcon fontSize={"small"}/>
                    </IconButton>
                </Box>
            </TitleBox>
            {
                todaysToons[0].title === "" ?
                    <Typography>Loading...</Typography>
                    :
                    <Grid container padding={"0 0 4vh 0"} columns={NUM_TOONS * 2} spacing={2}>
                        {
                            todaysToons.map((character, index) => {
                                return (
                                    <Grid
                                        padding={"0 0 1vh 0"}
                                        key={character.resource}
                                        item
                                        xs={NUM_TOONS * 2}
                                        md={NUM_TOONS}
                                        lg={NUM_TOONS / 2}>
                                        <CharacterGuessBox
                                            detailedCharacter={todaysToons[index]}
                                            titleList={titleList}
                                            characterList={characterList}
                                            finalGuessMode={finalGuessMode}
                                        />
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
            }
            <Button
                sx={{
                margin: "0 10px 0 10px",
                backgroundColor: `${theme.palette.primary.main}`,
                color: `${theme.palette.common.black}`, width:"100px"
                }}
                onClick={() => {setFinalGuessMode(true)}}
            >Guess</Button>
        </Stack>
    );
}